<template>
  <v-container>
    <v-row dense justify="center" no-gutters>
      <v-col cols="12" sm="10">
        <h1> Blog </h1>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
export default {
  name:'Blog',
}
</script>
